import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, mapProps } from '@shakacode/recompose';

import { FormattedMessage } from 'react-intl';
import { createEvent } from '~/utils/eventable';
import { sortByKey } from '../../utils/arrays';
import { formatPhone, nl2br } from '../../utils/utils';
import { withIntl } from '../../utils/withIntl';
import { withRestaurant } from '../../utils/withRestaurant';
import { themeShape, withTheme } from '../../utils/withTheme';
import { classNames, withStyles } from '../../utils/withStyles';

import CustomContent from '../shared/CustomContent';
import SocialIcon from '../../shared/SocialIcon';
import LocationHours from '../shared/LocationHours';
import ReservationWidget from '../shared/ReservationWidget';
import CustomImg from '../../shared/CustomImg';
import { AH, AHLevelProvider } from '../shared/AccessibleHeading';

const locationSectionRowStyles = theme => ({
  locationSectionHoursAligned: {
    marginTop: theme.spacing(5),
  },
  locationSectionRowAligned: {
    '@media (min-width: 992px)': {
      alignItems: 'center',
      display: 'flex',
    },
  },
});

const LocationSection = (props) => {
  const { classes } = props;
  const locations = props.locations.filter(l => l.lat && l.lng);
  if (locations.length === 0) {
    return null;
  }

  return (
    <div id="location" className="container-fluid" tabIndex="-1">
      <div className="row location">
        <div className="col-md-12 pm-custom-section-col">
          {!props.heading && (
            <AH variant="h3">{locations.length === 1 ? 'Our Location' : 'Our Locations'}</AH>
          )}
        </div>
      </div>
      <AHLevelProvider>
        {locations.map((location) => {
          let showReservation = false;
          let columnWidth = 12;
          let imgPosition = 'top';
          const hasOpeningRanges = location.openingRanges.length >= 1;
          const hasLocationPhoto = location.photoUrl !== null && location.photoUrl !== undefined;

          if (location.allowReservations) {
            if (location.openTableId || location.resyLinkUrl || location.yelpFriendlyId) {
              showReservation = true;
            }
          }
          if (showReservation) {
            if (hasLocationPhoto && location.showLocationPhotoInLocationSection) {
              // Display photo-address/hours-reservation
              imgPosition = 'first';
              columnWidth = 4;
            } else if (hasOpeningRanges) {
              columnWidth = 4;
            } else {
              columnWidth = 6;
            }
          } else if (hasOpeningRanges) {
            if (hasLocationPhoto && location.showLocationPhotoInLocationSection) {
              // Display address-photo-hours
              imgPosition = 'middle';
              columnWidth = 4;
            } else {
              // Center location info if no hours and reservation are set
              // If photo, display photo/addr
              columnWidth = 6;
            }
          }

          const alignRowStyles = (imgPosition === 'middle' || imgPosition === 'first');
          // Only rows displaying addr-photo-hrs or photo-addr/hrs-resrv will get styled

          /**
           * TODO: when style updates are complete, the conditional renderings of
           * the location photo and the hours ranges should be replaced with
           * material-ui grid components or  material grid compatible classes.
           */
          return (
            <div
              className={classNames(
                'row',
                'location',
                alignRowStyles ? classes.locationSectionRowAligned : null,
              )}
              key={location.id}
            >
              {hasLocationPhoto &&
                location.showLocationPhotoInLocationSection &&
                (imgPosition === 'first' || imgPosition === 'top') && (
                  <div className={`col-md-${columnWidth} pm-custom-section-col`}>
                    <CustomImg
                      alt={location.name}
                      src={location.photoUrl}
                    />
                  </div>
              )}
              <div className={`col-md-${columnWidth} pm-custom-section-col`}>
                <AH variant="h4">{location.name}</AH>
                <AHLevelProvider>
                  <p>
                    {nl2br(location.streetAddress)}<br />
                    {[`${location.city},`, location.state, location.postalCode].filter(Boolean).join(' ')}<br />
                  </p>
                  {location.displayPhone && (
                    <p>
                      <a
                        aria-label={`${location.displayPhone} ${location.name} ${props.t('generic.telephone')}`}
                        href={`tel:${location.displayPhone}`}
                        onClick={() => {
                          createEvent({
                            eventableId: location.id,
                            eventableType: 'RestaurantLocation',
                            eventType: 'call_attempt_event',
                            restaurantId: props.restaurantId,
                          });
                        }}
                      >
                        <span>
                          {formatPhone(location.displayPhone)}
                        </span>
                      </a>
                    </p>
                  )}
                  {location.socialHandles && (
                    <div className="location-social">
                      {sortByKey(location.socialHandles, 'position').map(handle => (
                        <a
                          aria-describedby="aria-new-window-2"
                          aria-label={props.t(`restaurants.social_handles.social_type_actions.${handle.socialType}`)}
                          href={handle.url}
                          key={`social_${handle.id}`}
                          rel="noopener"
                          target="_blank"
                        >
                          <SocialIcon icon={handle.socialType} />
                        </a>
                      ))}
                    </div>
                  )}
                  {location.customLocationContent && (
                    <CustomContent id={`location-content-${location.id}`} html={location.customLocationContent} />
                  )}
                  {location.openingRanges.length >= 1 &&
                    imgPosition === 'first' && (
                      <section className={classes.locationSectionHoursAligned}>
                        <AH variant="h4"><FormattedMessage id="locations.hours" defaultMessage="Hours" /></AH>
                        <LocationHours
                          ranges={location.openingRanges}
                          holidayRanges={location.holidayOpeningRanges}
                          firstDayOfWeek={location.firstDayOfWeek}
                          isLocationClosed={location.isLocationClosed}
                        />
                      </section>
                  )}
                </AHLevelProvider>
              </div>
              <AHLevelProvider>
                {hasLocationPhoto &&
                  location.showLocationPhotoInLocationSection &&
                  imgPosition === 'middle' && (
                    <div className={`col-md-${columnWidth} pm-custom-section-col`}>
                      <CustomImg
                        alt={location.name}
                        src={location.photoUrl}
                      />
                    </div>
                )}
                {showReservation && (
                  <div className="col-md-4 pm-custom-section-col" id="reservations">
                    <ReservationWidget
                      eventableId={location.id}
                      eventableType="RestaurantLocation"
                      location={location}
                      openTableIds={locations.map(l => l.openTableId).filter(Boolean)}
                      widgetId={location.slug}
                    />
                  </div>
                )}
                {location.openingRanges.length >= 1 &&
                  imgPosition !== 'first' && (
                    <div className={`col-md-${columnWidth} pm-custom-section-col`}>
                      <section>
                        <AH variant="h4"><FormattedMessage id="locations.hours" defaultMessage="Hours" /></AH>
                        <LocationHours
                          ranges={location.openingRanges}
                          holidayRanges={location.holidayOpeningRanges}
                          firstDayOfWeek={location.firstDayOfWeek}
                          isLocationClosed={location.isLocationClosed}
                        />
                      </section>
                    </div>
                )}
              </AHLevelProvider>
            </div>
          );
        })}
      </AHLevelProvider>
    </div>
  );
};

LocationSection.defaultProps = {
  heading: null,
};

LocationSection.propTypes = {
  classes: PropTypes.object.isRequired,
  heading: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.shape({
    city: PropTypes.string,
    displayPhone: PropTypes.string,
    id: PropTypes.number,
    lat: PropTypes.number,
    lng: PropTypes.number,
    name: PropTypes.string,
    phone: PropTypes.string,
    postalCode: PropTypes.string,
    slug: PropTypes.string,
    socialHandles: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      socialType: PropTypes.string,
      url: PropTypes.string,
    })),
    state: PropTypes.string,
    streetAddress: PropTypes.string,
  })).isRequired,
  restaurantId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  theme: themeShape.isRequired,
};

export default memo(compose(
  withTheme,
  withStyles(locationSectionRowStyles),
  withIntl,
  withRestaurant,
  mapProps(({ restaurant, ...props }) => ({
    ...props,
    restaurantId: restaurant.id,
  })),
)(LocationSection));
